'use strict'
import '../../styles/blocks/_meet-our-team.scss'
import '../../styles/cards/_card--member.scss'
import '../../styles/components/_loader.scss'
import {breakpoints} from "../breakpoints";
import {objectToFormData, toggleLoader} from "../helpers/misc";


const initEventHandlers = () => {

    let $teamContainer = $('.js-team')
    let $supervisorsContainer = $('.js-supervisors')


    $(document).on('click', '.js-team-term', function (e) {
        e.preventDefault()
        let $this = $(this)
        $('.js-team-term.active').removeClass('active')
        $this.addClass('active')

        if ($teamContainer.length > 0) {
            updateTeam()
        }

        if ($supervisorsContainer.length > 0) {
            updateSupervisors()
        }
    });

    $(document).on('change', '.js-team-term-select', function (e) {
        e.preventDefault()
        $(this).blur()

        if ($teamContainer.length > 0) {
            updateTeam()
        }

        if ($supervisorsContainer.length > 0) {
            updateSupervisors()
        }
    });
}

let teamQueueCount = 0;
let supervisorQueueCount = 0;

const updateTeam = () => {
    let $activeTerm = $('.js-team-term.active')
    let $teamResultContainer = $('.js-team-results')
    let $teamResultWrapper = $('.js-team-results-wrapper')

    if ($(window).width() <= breakpoints.lg) {
        $activeTerm = $('.js-team-term-select option:selected') // On mobile get the post type attribute
    }

    let activeTerm = $activeTerm.attr('data-term')
    let activeTaxonomy = $activeTerm.attr('data-taxonomy')

    if (teamQueueCount === 0) {
        toggleLoader($teamResultWrapper);
    }

    teamQueueCount = teamQueueCount + 1;
    let sequenceInTeamQueue = teamQueueCount

    $.ajax({
        type: 'POST',
        url: localized.ajax_url,
        data: objectToFormData({
            term: activeTerm ?? '',
            taxonomy: activeTaxonomy ?? '',
            action: 'load_team',
        }),
        dataType: 'json',
        contentType: false,
        processData: false,
        cache: true,
        success: function (response) {

            if (sequenceInTeamQueue === teamQueueCount) {
                if (response.data) {
                    $teamResultContainer.html(response.data.html)
                }

                setTimeout(function () {
                    toggleLoader($teamResultWrapper);
                }, 160)

                teamQueueCount = 0;
            }
        }
    });
}

const updateSupervisors = () => {
    let $activeTerm = $('.js-team-term.active')
    let $supervisorResultContainer = $('.js-supervisor-results')
    let $supervisorResultWrapper = $('.js-supervisor-results-wrapper')

    if ($(window).width() <= breakpoints.lg) {
        $activeTerm = $('.js-team-term-select option:selected') // On mobile get the post type attribute
    }

    let activeTerm = $activeTerm.attr('data-term')
    let activeTaxonomy = $activeTerm.attr('data-taxonomy')

    if (supervisorQueueCount === 0) {
        toggleLoader($supervisorResultWrapper);
    }

    supervisorQueueCount = supervisorQueueCount + 1;
    let sequenceInSupervisorQueue = supervisorQueueCount

    $.ajax({
        type: 'POST',
        url: localized.ajax_url,
        data: objectToFormData({
            term: activeTerm ?? '',
            taxonomy: activeTaxonomy ?? '',
            action: 'load_supervisors',
        }),
        dataType: 'json',
        contentType: false,
        processData: false,
        cache: true,
        success: function (response) {
            if (sequenceInSupervisorQueue === supervisorQueueCount) {
                if (response.data) {
                    $supervisorResultContainer.html(response.data.html)
                }

                setTimeout(function () {
                    toggleLoader($supervisorResultWrapper);
                }, 160)

                supervisorQueueCount = 0;
            }
        }
    });
}


$(window).on('load', function () {

    let $teamContainer = $('.js-team')
    let $supervisorsContainer = $('.js-supervisors')

    if ($teamContainer.length > 0 || $supervisorsContainer.length > 0) {
        initEventHandlers()
    }

    if ($teamContainer.length > 0) {
        updateTeam()
    }

    if ($supervisorsContainer.length > 0) {
        updateSupervisors()
    }
});
